.footer-v1 {

	.main-footer {

		background-color: #fff;
		color: var(--footer-font-color);
		border-top: 5px solid var(--footer-border-top-color);
		padding-left: 0;
		padding-right: 0;
		padding-top: 30px;
		padding-bottom: 30px;

		.col-lg-3 {
			@media all and (min-width: 768px) and (max-width: 991px) {
				margin-bottom: 0 !important;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				-webkit-box-flex: 0;
				flex: 0 0 33.33%;
				max-width: 33.33%;
			}

		}

		.container {
			@media (max-width: 767px) {
				max-width: 100%;
			}
		}

		.headline {
			font-size: 20px;
			color: var(--footer-font-color);
			font-weight: 500;
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
			}
		}


		.v-logo {
			text-align: right;

			img {
				max-width: 100px;
				width: 100%;
			}
		}


		a {
			color: #666;

			&:hover {
				color: #212529;
			}
		}

		.social-icons {

			li {

				a {
					color: #666;
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}

		.list-unstyled {
			display: flex;
			flex-wrap: wrap;

			li {
				width: 50%;
				margin: 0 !important;

				a {
					position: relative;

					&:before {
						display: block;
						content: "\f0da";
						font-family: 'FontAwesome';
						margin-right: 5px;
						display: inline-block;
					}
				}
			}
		}



		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #666;
			margin: 0 5px 0 0;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
	}

}